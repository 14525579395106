<template>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <h3><div class="point"></div><div class="als_point"></div> - Hall of Masters Gewinner</h3>

            <div className="box">
                Diese Seite wird nach dem 25. ALS Champion Masters mit Inhalt gefüllt.
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
    name: "WinnersHallOfFame",
    created()
    {
        useHead({
            title: "ALS Hall of Masters Gewinner - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>