import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/Home.vue";
import NewsView from "../views/News.vue";
import PresaleView from "../views/PreSale.vue";
import SponsorsView from "../views/Sponsors.vue";
import ContactView from "../views/Contact.vue";
import PrivacyView from "../views/Privacy.vue";
import ImprintView from "../views/Imprint.vue";

import MastersRoutes from './masters';
import FluechterRoutes from './fluechter';
import SparkasseRoutes from './sparkasse';
import GalleryRoutes from './gallery';
import HallOfFameRoutes from './hallOfFame';

const routes: Array<RouteRecordRaw> = [
    ...MastersRoutes,
    ...FluechterRoutes,
    ...GalleryRoutes,
    ...HallOfFameRoutes,
    ...SparkasseRoutes,
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
        path: "/vorverkauf",
        name: "vorverkauf",
        component: PresaleView,
    },
    {
        path: "/sponsoren",
        name: "sponsoren",
        component: SponsorsView,
    },
    {
        path: "/news/:id/:title",
        name: "news",
        component: NewsView,
    },
    {
        path: "/impressum",
        name: "impressum",
        component: ImprintView,
    },
    {
        path: "/kontakt",
        name: "kontakt",
        component: ContactView,
    },
    {
        path: "/datenschutz",
        name: "datenschutz",
        component: PrivacyView,
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
