<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <h3><div class="point"></div> Turniersieger</h3>
      <div class="box">
        <h5>Bisherige Sieger</h5>
        <table class="times_prices">
          <tbody>
          <tr>
            <td style="text-align: center;">2019</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2018</td>
            <td>SV Schmallenberg/Fredeburg</td>
          </tr>
          <tr>
            <td style="text-align: center;">2017</td>
            <td>SV Brilon</td>
          </tr>
          <tr>
            <td style="text-align: center;">2016</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2015</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2014</td>
            <td>SSV Meschede</td>
          </tr>
          <tr>
            <td style="text-align: center;">2013</td>
            <td>TuS Sundern</td>
          </tr>
          <tr>
            <td style="text-align: center;">2012</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2011</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2010</td>
            <td>SuS Langscheid/Enkhausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2009</td>
            <td>SuS Langscheid/Enkhausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2008</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2007</td>
            <td>SuS Langscheid/Enkhausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2006</td>
            <td>RW Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2005</td>
            <td>BC Eslohe</td>
          </tr>
          <tr>
            <td style="text-align: center;">2004</td>
            <td>RW&nbsp;Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2003</td>
            <td>RW&nbsp;Erlinghausen</td>
          </tr>
          <tr>
            <td style="text-align: center;">2002</td>
            <td>TuS Sundern</td>
          </tr>
          <tr>
            <td style="text-align: center;">2001</td>
            <td>VfB Marsberg</td>
          </tr>
          <tr>
            <td style="text-align: center;">2000</td>
            <td>SSV Meschede</td>
          </tr>
          <tr>
            <td style="text-align: center;">1999</td>
            <td>VfB&nbsp;Marsberg</td>
          </tr>
          <tr>
            <td style="text-align: center;">1998</td>
            <td>TuS Sundern</td>
          </tr>
          <tr>
            <td style="text-align: center;">1997</td>
            <td>SSV Meschede</td>
          </tr>
          <tr>
            <td style="text-align: center;">1996</td>
            <td>SSV Meschede</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import {useHead} from "@vueuse/head";

export default {
  name: "TournamentWinner",

    created()
    {
        useHead({
            title: "Turniersieger - " + this.tournamentName
        });
    }
}
</script>