<template>
    <div id="content">
        <div class="row" v-if="!loadedImages">
            <div class="col-12">
                <h2 style="color:white;text-align:center">Bildergalerie wird geladen...</h2>
            </div>
        </div>

        <div class="row" v-if="loadedImages">
            <div class="col-sm-12" style="margin-bottom:30px;">
                <div class="box galleryO_box">
                    <LightGallery
                        :images="images"
                        :index="index"
                        :disable-scroll="true"
                        @close="index = null"
                    />
                    <ul style="display:none">
                        <li
                            v-for="(thumb, thumbIndex) in images"
                            :key="thumbIndex"
                            @click="index = thumbIndex"
                        >
                            <img :src="thumb">
                        </li>
                    </ul>

                    <div id="gallery" style="justify-content: center">
                        <div class="col-xs-6 col-sm-4 col-md-2" style="margin-bottom:10px;" v-for="(img, item_index) in images" v-bind:key="item_index" :style="{'background-image': 'url(' + (img) + ')'}">
                            <div @click="index = item_index">
                                <img :src="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.gallery_box{
    display:flex;
    flex-direction: column;
    transition: all 0.4s;
}
.gallery_box .img{
    width:100%;
    height:auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.gallery_box .img img{
    visibility: hidden;
    width:100%;
    max-height: 100%;
}
.gallery_box .info{
    width:100%;
    bottom:0;
    color:white;
    padding:15px;
    background:rgba(0,0,0,0.5);
    transition: all 0.4s;
}
.gallery_box .info .head{
    display:flex;
}
.gallery_box .info .head .verein{
    font-size:13px;
    text-align: left;
}
.gallery_box .info .head .date{
    font-size:13px;
    margin-left:auto;
    text-align: right;
}
.gallery_box .info .headline{
    width:100%;
    font-size:18px;
    text-align: left;
}
.gallery_box .info .description{
    display: none;
    transition: all 0.4s;
}
.gallery_box .info:hover .description{
    transition: all 0.4s;
    display:block;
}
.gallery_box .info .read_more{
    border:1px solid white;
    color:white;
    padding:10px;
    font-size:15px;
    float:right;
    cursor:pointer;
    transition: all 0.4s;
}
.gallery_box .info .read_more:hover{
    background:white;
    color:black;
}

#gallery{
    display:flex;
    flex-wrap: wrap
}
#gallery div{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin:5px;
    cursor:pointer;
}
#gallery img{
    width:100%;
    height:auto;
    visibility: hidden;
}
</style>

<script>
import LightGallery from 'vue-light-gallery';
import axios from "axios";

export default {
    name: "ImagesView",
    components: {
        LightGallery,
    },

    data(){
        return {
            loadedImages: false,
            gallery: null,
            images: [],
            index: null,
        }
    },
    created() {
        this.LoadGallery();
    },
    methods: {
        LoadGallery()
        {
            axios
                .get(`https://kleinolpe.de/api/championmasters/galleries/${this.$route.params.id}`)
                .then(
                    (response) => {
                        this.gallery = response.data;
                        this.images = [];

                        for(let i = 0; i < this.gallery.images.length; i++)
                        {
                            this.images.push("https://kleinolpe.de/data/gallery/" + this.gallery.path + "/" + this.gallery.images[i].path);
                        }

                        this.loadedImages = true;
                    },
                    () => {
                        this.$router.push({name: "gallery"});
                    }
                );
        }
    }
}
</script>
