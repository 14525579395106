<template>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <h3><div class="point"></div>
                <div class="sparkassen_point"></div>- A-Junioren Cup</h3>

            <div className="box">
                <div className="main-timeline">
                    <div class="timeline" :class="team.year % 2 !== 0 ? 'left' : 'right'" v-for="team in winners" v-bind:key="team.year">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3>{{ team.year}}</h3>
                                <div className="winnerImg"
                                     :style="{'background-image': 'url(/img/sparkasse/' + (team.img) + ')'}">
                                    <img :src="'/img/sparkasse/' + (team.img)" />
                                </div>

                                <p className="mt-4 mb-0" style="text-align: center">{{ team.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
    name: "SparkassenWinners",
    data()
    {
        return {
            winners: []
        }
    },

    created()
    {
        useHead({
            title: "A-Junioren Sparkassencup Sieger - " + this.tournamentName
        });

        this.winners = [
            {
                year: "2019",
                img: "2019.jpg",
                text: "JSG Fredeburg/Schmallenberg-Fredeburg"
            },
            {
                year: "2018",
                img: "2018.jpg",
                text: "BC Eslohe"
            },
            {
                year: "2017",
                img: "2017.jpg",
                text: "JSG Bödefeld/Henne-Rartal"
            },
            {
                year: "2016",
                img: "2016.jpg",
                text: "BC Eslohe"
            },
            {
                year: "2015",
                img: "2015.jpg",
                text: "SG Eversberg/Heinrichsthal/Wehrstapel"
            },
            {
                year: "2014",
                img: "2014.jpg",
                text: "BC Eslohe"
            },
            {
                year: "2013",
                img: "2013.jpg",
                text: "BC Eslohe"
            },
            {
                year: "2012",
                img: "2012.jpg",
                text: "SSV Meschede"
            },
            {
                year: "2011",
                img: "2011.jpg",
                text: "BC Eslohe"
            },
            {
                year: "2010",
                img: "2010.jpg",
                text: "JSG Freienohl/Wennemen/Olpe"
            },
            {
                year: "2009",
                img: "2009.jpg",
                text: "BC Eslohe"
            },
            {
                year: "2008",
                img: "2008.jpg",
                text: "JSG Wennemen/Olpe"
            },
            {
                year: "2007",
                img: "2007.jpg",
                text: "SSV Meschede"
            },
            {
                year: "2006",
                img: "2006.jpg",
                text: "SSV Meschede"
            },
            {
                year: "2005",
                img: "2005.jpg",
                text: "JSG Calle/Wallen"
            },
        ];
    }
}
</script>

<style scoped>

</style>