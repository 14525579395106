<template>
    <div class="row">
        <div class="col-md-12 col-lg-8">
            <h3>
                <div class="point"></div> Impressum
            </h3>

            <div class="box">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImprintView"
}
</script>

<style scoped>

</style>