<template>
    <div class="row">
        <div class="col-sm-12 col-md-9">
            <h3><div class="point"></div><div class="autocenter_meschede_point"></div>- Flüchterpreis</h3>
            <div class="box">
                <p>Der &#8222;Christian Flüchter &#8211; Gedächtnispreis&#8220; wird im Rahmen des {{ tournamentName }} ausgelobt, um den viel zu früh verstorbenen Spieler Christian Flüchter in lebendiger Erinnerung zu behalten.</p>
                <p>Der vom SC 1911 Olpe e.V. im Jahr 2003 ins Leben gerufene Preis wird an den von allen Zuschauern gewählten besten Spieler des Turniers verliehen und ist mit einem Preisgeld  von insgesamt 500,- EURO dotiert, das sich wie folgt zusammensetzt:</p>
                <ul>
                    <li>der „beste Spieler“ des Turniers erhält den Pokal der A.L.S. Allgemeine Land- und Seespedition GmbH und ein Preisgeld von 100,- EURO</li>
                    <li>das zweite Preisgeld in Höhe von 200,- EURO fließt einem vom Veranstalter vorgegebenen gemeinnützigen Zweck zu</li>
                    <li>weitere 200,- EURO erhält der „beste Spieler“ des Turniers, der seinen Betrag einem von ihm bestimmten gemeinnützigen Zweck zur Verfügung stellt. Die Bestimmung beschränkt sich auf gemeinnützige Einrichtungen wie Schulen, Kindergärten, Kindertagesstätten oder Heime, sowie Einzelschicksale oder sozialie Brennpunkte. Bei dieser Auswahl sollte er sich auf seinen Heimatort beschränken.</li>
                </ul>
                <p>Der „Christian Flüchter &#8211; Gedächtnispreis“ wird ermöglicht durch die finanzielle Unterstützung der Firma A.L.S. Allgemeine Land- und Seespedition GmbH aus Arnsberg.</p>
                <p style="margin-bottom:0">Dafür ein herzliches Dankeschön!</p>
                <br><br>
                <b>Zur Person:</b><br>
                <p>Christian Flüchter, geb. 2.2.1964, spielte während seiner Jugend Fußball bei seinem Heimatclub, dem SC Olpe. Auch die ersten Seniorenjahre blieb er seinem Stammverein treu. Ein Spieler von Christians Format ist jedoch auch unter lokalpatriotischen Gesichtspunkten nur schwer für einen längeren Verbleib bei einem mittelmäßig erfolgreichen B-Liga-Club zu begeistern. Folgerichtig wollte Christian sich weiterentwickeln und wechselte zur Saison 1985/86 zum Nachbarverein TuRa Freienohl, mit dem er zwei Jahre lang in der Bezirksliga spielte. Anschließend wechselte Christian zum Bezirksliga-Konkurrenten TuS Jahn Berge, wo er unter anderem mit seinem Freund aus Olper Jugendzeiten Ferdi Hütter (dem seinerzeitigen Top-Goalgetter der Bezirksliga) zusammenspielte. Nach schwerer Krankheit verstarb Christian im jungen Alter von nur 28 Jahren am 28.11.1992. Er hinterließ seine Ehefrau Gabi und seinen Sohn Marius.</p>
                <p>Wir haben mit Christian einen talentierten Kicker verloren, schmerzvoller ist jedoch der Verlust eines geliebten Freundes, Sohnes, Ehemannes und Vaters. Der „Christian Flüchter &#8211; Gedächtnispreis“ soll daher an seinen Namensgeber als guten Fußballer, viel mehr jedoch an seine lebensfrohe, zuvorkommende und wertschätzende Persönlichkeit erinnern.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OverviewView"
}
</script>