<template>
    <div class="row">
        <div class="col-md-12 col-lg-9">
            <h3>
                <div class="point"></div> Sponsoren
            </h3>

            <div class="box" style="background: white;">
                <table style="background: white; text-align: center">
                    <tbody>
                    <tr>
                        <td style="vertical-align: middle;"><a href="https://www.als-arnsberg.de"><img class="aligncenter wp-image-6144" src="img/sponsoren/A.L.S._2018_2-300x155.png" alt="" width="115" height="60" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.sparkasse-mitten-im-sauerland.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/sparkasse_meschede_logo.png" alt="" width="286" height="90" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.veltins.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/veltins.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="https://www.pce-instruments.com/deutsch/pce-deutschland-gmbh-pruefgeraete-anbieter_1.htm"><img class="aligncenter wp-image-6144" src="img/sponsoren/PCE-inst-Logo.7ff1a5c3.png" alt="" width="220" height="90" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="http://www.watex.de"><img class="alignnone wp-image-4283 size-medium" src="img/sponsoren/Watex-1-300x168.png" alt="" width="300" height="168"/></a></td>
                        <td style="vertical-align: middle;"> <a href="http://www.gebhardt-stahl.de"><img class="aligncenter wp-image-4284 size-medium" src="img/sponsoren/Gebhardt-300x37.png" alt="" width="300" height="37" /></a></td>
                        <td style="vertical-align: middle;"><a href="https://www.kwk-kuechen.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/kwk_kuechen.png" alt="" width="200" height="100" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.steuerberatung-buettner.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/buettner.jpg" alt="" border="0" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="http://www.gkpetfoodgmbh.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/gk-petfood.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="https://www.kremer-baeckerei.de/"><img class="aligncenter" src="img/sponsoren/kremer.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.hotel-huetter.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/huetter.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="https://www.scheiwe-holz.de/"><img class="alignnone size-medium wp-image-11626" src="img/sponsoren/holz.png" alt="" width="300" height="95" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="http://www.key-security.de" target="_blank" rel="noopener noreferrer"><img class="size-full wp-image-4089 alignnone" src="img/sponsoren/K.E.Y.png" alt="k-e-y" width="156" height="109" /></a></td>
                        <td style="vertical-align: middle;"> <a href="https://www.championmasters.de/web/wp-content/uploads/2018/11/IBIS_QR.jpg"><img class="alignnone wp-image-4225 size-medium" src="img/sponsoren/ibis370x110-300x89.jpg" alt="" width="300" height="89" srcset="https://www.championmasters.de/web/wp-content/uploads/2017/11/ibis370x110-300x89.jpg 300w, https://www.championmasters.de/web/wp-content/uploads/2017/11/ibis370x110.jpg 370w" sizes="(max-width: 300px) 100vw, 300px" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.bremer-automobiltechnik.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/bremer.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.zacharias-reisen.de" target="_blank" rel="noopener noreferrer"><img class="size-full wp-image-4092 alignnone" src="img/sponsoren/Zacharias.png" alt="zacharias" width="218" height="45" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="https://eickelmann-pm.de/" target="_blank" rel="noopener noreferrer"><img class="size-medium wp-image-4090 alignnone" src="img/sponsoren/Eickelmann.png" alt="Eickelmann" width="300" height="98" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.kremer-schemme.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/kremer-schemme.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.noeke.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/noeke.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.cds-container.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/cds.jpg" alt="" border="0" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="http://www.mediaprint-pb.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/mediaprint.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.ruhrtal-tb.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/ruhrtal_beton.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.autohandel-covic.com" target="_blank" rel="noopener noreferrer"><img class="size-full wp-image-4087 aligncenter" src="img/sponsoren/Covic.png" alt="covic" width="153" height="73" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.gambrinus-arnsberg.com" target="_blank" rel="noopener noreferrer"><img class="size-full wp-image-4088 alignnone" src="img/sponsoren/Gambrinus1.png" alt="gambrinus1" width="177" height="107" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="https://www.autohaus-hoffmann-sundern.de/de.html" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/Hoffmann.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.winterberg-touristik.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/Winterberg_Tourismus.png" alt="" width="244" height="82" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.becker-druck-verlag.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/becker-druck.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.franz-trippe.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/trippe.jpg" alt="" border="0" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"><a href="http://stuck-rocholl.com/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/rocholl.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.buerger-eslohe.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/buerger.jpg" alt="" border="0" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.wuerttembergische.de" target="_blank" rel="noopener noreferrer"><img class="size-medium wp-image-4091 aligncenter" src="img/sponsoren/Württembergische-300x83.png" alt="wuerttembergische" width="300" height="83" srcset="https://www.championmasters.de/web/wp-content/uploads/2016/12/Württembergische-300x83.png 300w, https://www.championmasters.de/web/wp-content/uploads/2016/12/Württembergische.png 337w" sizes="(max-width: 300px) 100vw, 300px" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.kaiser-fenster.de/" target="_blank" rel="noopener noreferrer"><img class="aligncenter" src="img/sponsoren/kaiser.jpg" alt="" border="0" /></a></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"> <a href="http://www.werner-langer.de"><img class="wp-image-3868 size-full aligncenter" src="img/sponsoren/langer.jpg" alt="" width="104" height="73" /></a></td>
                        <td style="vertical-align: middle;"><a href="http://www.spedition-haeger.de/"><img class="wp-image-3868 size-full aligncenter" src="img/sponsoren/häger.png" alt="" width="104" height="73" /></a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "SponsorsView",

    created()
    {
        useHead({
            title: "Sponsoren - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>