<template>
    <div class="row" v-if="news != null">
        <div class="col-12 col-md-4 col-lg-3 order-lg-1 order-sm-1 order-md-1">
            <div class="nimage" :style="{'background-image': 'url(https://kleinolpe.de/data/news/' + news.image + ')'}">
                <img :src="'https://kleinolpe/data/news/' + news.image">
            </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 order-lg-2 order-sm-2 order-md-2">
            <h3><div class="point"></div> {{ news.headline }}</h3>
            <div class="box" v-html="news.text"></div>
        </div>
    </div>
</template>

<style>
p{
    margin: 0;
    padding: 0;
}

.nimage {
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px
}
.nimage img{
    visibility: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
}
</style>

<script>
import axios from "axios";
import {useHead} from "@vueuse/head";

export default {
    name: "NewsView",

    data()
    {
        return {
            news: null
        }
    },

    created()
    {
        axios.get('https://kleinolpe.de/api/championmasters/news/' + this.$route.params.id)
            .then(response => {
                this.news = response.data;

                useHead({
                    title: this.news.headline + " - " + this.tournamentName,
                    meta: [
                        {
                            name: `description`,
                            content: this.news.description
                        },
                    ],
                });
            })
            .catch(error => console.log(error))
    }
}
</script>

<style scoped>

</style>