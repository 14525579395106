<template>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <h3><div class="point"></div>
                <div class="autocenter_meschede_point"></div>- Flüchterpreis Gewinner</h3>



            <div class="box">
                <div className="main-timeline">
                    <div class="timeline" :class="player.year % 2 !== 0 ? 'left' : 'right'" v-for="player in players" v-bind:key="player.year">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3>{{ player.year}}</h3>
                                <div className="winnerImg"
                                     :style="{'background-image': 'url(/img/fluechter/' + (player.img) + ')'}">
                                    <img :src="'/img/torwart/' + (player.img)" />
                                </div>

                                <p className="mt-3 mb-0" style="text-align: center">{{ player.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FluechterPrice",

    data()
    {
        return {
            players: []
        }
    },

    created()
    {
        this.players = [
            {
                year: "2019",
                img: "FP_2019.jpg",
                text: "Justus Sapp (BC Eslohe)"
            },
            {
                year: "2018",
                img: "FP_2018.jpg",
                text: "Bilal Akgüvercin (RW Erlinghausen)"
            },
            {
                year: "2017",
                img: "FP_2017.jpg",
                text: "Bilal Akgüvercin (RW Erlinghausen)"
            },
            {
                year: "2016",
                img: "FP_2016.jpg",
                text: "Bilal Akgüvercin (RW Erlinghausen)"
            },
            {
                year: "2015",
                img: "FP_2015.png",
                text: "Burak Yavuz (SSV Meschede)"
            },
            {
                year: "2014",
                img: "FP_2014.png",
                text: "Burak Yavuz (SSV Meschede)"
            },
            {
                year: "2013",
                img: "FP_2013.png",
                text: "Daniel Berlinski (RW Erlinghausen)"
            },
            {
                year: "2012",
                img: "FP_2012.png",
                text: "Daniel Berlinski (RW Erlinghausen)"
            },
            {
                year: "2011",
                img: "FP_2011.png",
                text: "Burak Yavuz (SSV Meschede)"
            },
            {
                year: "2010",
                img: "FP_2010.png",
                text: "Philipp Hofmann (HSK A-Junioren-Auswahl)"
            },
            {
                year: "2009",
                img: "FP_2009.png",
                text: "Paul Bender (VfB Marsberg)"
            },
            {
                year: "2008",
                img: "FP_2008.png",
                text: "Daniel Berlinski (RW Erlinghausen)"
            },
            {
                year: "2007",
                img: "FP_2007.png",
                text: "Steffen Müller (HSK A-Junioren-Auswahl)"
            },
            {
                year: "2006",
                img: "FP_2006.png",
                text: "Dominik Berens (BC Eslohe)"
            },
            {
                year: "2005",
                img: "FP_2005.png",
                text: "René Eickhoff (SG Grafschaft)"
            },
            {
                year: "2004",
                img: "FP_2004.png",
                text: "Jonas Schmidt (HSK A-Junioren-Auswahl)"
            },
            {
                year: "2003",
                img: "FP_2003.png",
                text: "Christian Spielmann (TuS Sundern)"
            },
        ];
    }
}
</script>

<style scoped>

</style>