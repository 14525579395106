<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <h3><div class="point"></div><div class="als_point"></div> - Hall of Masters</h3>
      <div class="box">
          Der Beitrag zu dieser Seite folgt in Kürze!
      </div>
    </div>
  </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "HallOfFame",

    created()
    {
        useHead({
            title: "ALS Hall of Masters - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>