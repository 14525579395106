<template>
    <div className="row">
        <div className="col-12">
            <h3>
                <div className="point"></div>
                <div class="landhotel_huetter_point"></div> -
                Torschützenkönige
            </h3>
            <div className="box">
                <div className="main-timeline">
                    <div class="timeline" :class="player.year % 2 !== 0 ? 'left' : 'right'" v-for="player in torschuetzen" v-bind:key="player.year">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3>{{ player.year}}</h3>
                                <div className="winnerImg"
                                     :style="{'background-image': 'url(/img/torschuetzen/' + (player.img) + ')'}">
                                    <img :src="'/img/torschuetzen/' + (player.img)" />
                                </div>

                                <p className="mt-4 mb-0" style="text-align: center">{{ player.text }}</p>
                                <p className="mb-0" style="font-weight: bold;text-align:center">{{ player.goals }} Tore</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {useHead} from "@vueuse/head";

export default {
    name: "TopScorer",

    data()
    {
        return {
            torschuetzen: []
        }
    },

    created()
    {
        useHead({
            title: "Landhotelhütter Torschützenkönige - " + this.tournamentName
        });

        this.torschuetzen = [
            {
                year: "2019",
                img: "TK_2019.jpg",
                text: "Bilal Akgüvercin (RW Erlinghausen) & Justus Sapp (BC Eslohe)",
                goals: "8"
            },
            {
                year: "2018",
                img: "TK_2018.jpg",
                text: "Marco Gorges (SV Schmallenberg/Fredeburg)",
                goals: "14"
            },
            {
                year: "2017",
                img: "TK_2017.jpg",
                text: "Marco Gorges (SV Schmallenberg/Fredeburg)",
                goals: "10"
            },
            {
                year: "2016",
                img: "TK_2016.png",
                text: "Pascal Raulf (RW Erlinghausen)",
                goals: "8"
            },
            {
                year: "2015",
                img: "TK_2015.png",
                text: "Dirk Stoltefaut (TuRa Freienohl)",
                goals: "8"
            },
            {
                year: "2014",
                img: "TK_2014.png",
                text: "Florian Hümmler & Theo Molitor (BC Eslohe)",
                goals: "5"
            },
            {
                year: "2013",
                img: "TK_2013.png",
                text: "Bilal Akgüvercin (RW Erlinghausen)",
                goals: "-"
            },
            {
                year: "2012",
                img: "TK_2012.png",
                text: "Frederik Schnock (FC Arpe-Wormbach) & Roberto Busacca (RW Erlinghausen)",
                goals: "-"
            },
            {
                year: "2011",
                img: "TK_2011.png",
                text: "Burak Yavus (SSV Meschede)",
                goals: "-"
            },
            {
                year: "2010",
                img: "TK_2010.png",
                text: "Jonas Schmidt (SuS Langscheid/Enkhausen)",
                goals: "-"
            },
            {
                year: "2009",
                img: "TK_2009.png",
                text: "Matthias Kauke (SuS Langscheid/Enkhausen)",
                goals: "-"
            },
            {
                year: "2008",
                img: "TK_2008.png",
                text: "Daniel Berlinski (RW Erlinghausen)",
                goals: "-"
            },
            {
                year: "2007",
                img: "TK_2007.png",
                text: "Steffen Müller (HSK A-Junioren Auswahl)",
                goals: "-"
            },
            {
                year: "2006",
                img: "TK_2006.png",
                text: "Ali Güllü (RW Erlinghausen)",
                goals: "-"
            }
        ];
    }
}
</script>

<style scoped>

</style>