<template>
    <div id="topbar" v-if="$route.name.startsWith('home')">
        <p>
            28. – 30. Dezember in der Mescheder Dünnefeldhalle –
            <router-link :to="{name: 'masters@entrancefeeds'}">
                Anstoßzeiten / Eintrittspreise anzeigen
            </router-link>
        </p>
    </div>

    <header v-if="$route.name.startsWith('home')">
        <div id="logos" :class="scrollPos > 90 && scrollPos < headerHeight - 20 ? 'fixed' : ''">
            <div class="olpe_als" style="margin-right: auto;">
                <div class="olpe_logo"></div>
                <div class="als" style="margin-top: 20px"></div>
            </div>
            <div id="cm_logo"></div>
            <div class="olpe_als" style="margin-left: auto">
                <div class="als" style="margin-bottom: 20px"></div>
                <div class="olpe_logo"></div>
            </div>
        </div>
        <div id="playtimes">
            <section>
                1. Vorrundentag<br />
                <b>28.12. ab 16:30 Uhr</b>
            </section>
            <section>
                2. Vorrundentag<br />
                <b>29.12. ab 16:30 Uhr</b>
            </section>
            <section>
                Finaltag<br />
                <b>30.12. ab 14:00 Uhr</b>
                <div style="font-size: 19px;margin-bottom:-20px;">Einlass ab 13:30 Uhr</div>
            </section>
        </div>
    </header>

    <nav v-bind:class="[scrollPos > headerHeight || !$route.name.startsWith('home')  ? 'fixed' : '', showMainNavi ? 'open' : 'close']">
        <div id="mobile_top">
            <div id="olpe_small_logo" v-on:click="subNavi = ''; $router.push({name: 'home'}); moveUp();"></div>
            <div id="cm_small_logo" :class="scrollPos > headerHeight || !$route.name.startsWith('home') ? 'fullOpacity' : ''" @click="subNavi = ''; $router.push({name: 'home'}); moveUp();"></div>

            <div id="burger" @click="TriggerNavigation()">
                <svg-icon type="mdi" :path="mdiMenu" width="50" height="50"></svg-icon>
            </div>
        </div>

        <div id="navitems">
            <li :class="subNavi === 'masters' ? 'active' : ''" v-on:click="subNavi = (subNavi !== 'masters') ? 'masters' : ''">Masters</li>
            <a href="https://www.championmasters.de/spielplan/" target="_blank">Spielplan <svg-icon type="mdi" :path="mdiOpenInNew"></svg-icon></a>
            <router-link :to="{name: 'vorverkauf'}" :class="$route.name.startsWith('vorverkauf') ? 'active' : ''" v-on:click="subNavi = '';moveUp();">Vorverkauf</router-link>
            <router-link :to="{name: 'sponsoren'}" :class="$route.name.startsWith('sponsoren') ? 'active' : ''" v-on:click="subNavi = '';moveUp();">Sponsoren</router-link>
            <li :class="subNavi === 'fluechterpreis' ? 'active' : ''"
                @click="subNavi = (subNavi !== 'fluechterpreis') ? 'fluechterpreis' : ''">Flüchterpreis</li>
            <router-link :to="{name: 'galerie@overview'}" :class="$route.name.startsWith('galerie@overview') ? 'active' : ''" v-on:click="subNavi = '';moveUp();">Bildergalerie</router-link>
            <li :class="subNavi === 'hallOfMasters' ? 'active' : ''"
                @click="subNavi = (subNavi !== 'hallOfMasters') ? 'hallOfMasters' : ''">ALS Hall of Masters</li>
            <li :class="subNavi === 'sparkassenCup' ? 'active' : ''"
                @click="subNavi = (subNavi !== 'sparkassenCup') ? 'sparkassenCup' : ''">Sparkassen Cup</li>
        </div>

        <ul id="handy_navitems">
            <li class="has-items" :class="subNavi === 'masters' ? 'active' : ''">
                <span v-on:click="subNavi = (subNavi !== 'masters') ? 'masters' : ''">
                    Masters
                    <span class="dropdownmenu"><svg-icon type="mdi" :path="mdiChevronDown" width="25" height="25"></svg-icon></span>
                </span>
                <ul @click="subNavi = '';TriggerNavigation()">
                    <li><router-link :to="{name: 'masters@entrancefeeds'}" :class="$route.name.startsWith('masters@entrancefeeds') ? 'active' : ''" v-on:click="moveUp">Eintrittspreise & Anstoßzeiten</router-link></li>
                    <li><router-link :to="{name: 'masters@tournamentwinner'}" :class="$route.name.startsWith('masters@tournamentwinner') ? 'active' : ''" v-on:click="moveUp">Turniersieger</router-link></li>
                    <li><router-link :to="{name: 'masters@topscorer'}" :class="$route.name.startsWith('masters@topscorer') ? 'active' : ''" v-on:click="moveUp">Torschützenkönige</router-link></li>
                    <li><router-link :to="{name: 'fluechter@gewinner'}" :class="$route.name.startsWith('fluechter@gewinner') ? 'active' : ''" v-on:click="moveUp">Flüchterpreis-Gewinner</router-link></li>
                    <li><router-link :to="{name: 'masters@goalkeeperprice'}" :class="$route.name.startsWith('masters@goalkeeperprice') ? 'active' : ''" v-on:click="moveUp">Torwartpreis</router-link></li>
                    <li><router-link :to="{name: 'masters@prices'}" :class="$route.name.startsWith('masters@prices') ? 'active' : ''" v-on:click="moveUp">Preisgelder</router-link></li>
                    <li><router-link :to="{name: 'masters@history'}" :class="$route.name.startsWith('masters@history') ? 'active' : ''" v-on:click="moveUp">Historie</router-link></li>
                    <li><a href="../Turnierordnung18.pdf" target="_blank">Turnierordnung <svg-icon type="mdi" :path="mdiOpenInNew" width="12" height="12"></svg-icon></a></li>
                </ul>
            </li>

            <li>
                <span @click="subNavi = '';TriggerNavigation()">
                    <a href="https://www.championmasters.de/spielplan/" target="_blank">Spielplan <svg-icon type="mdi" :path="mdiOpenInNew"></svg-icon></a>
                </span>
            </li>
            <li>
                <span @click="subNavi = '';TriggerNavigation()">
                    <router-link :to="{name: 'vorverkauf'}" :class="$route.name.startsWith('vorverkauf') ? 'active' : ''" v-on:click="subNavi = '';moveUp();">Vorverkauf</router-link>
                </span>
            </li>
            <li>
                <span @click="subNavi = '';TriggerNavigation()">
                    <router-link :to="{name: 'sponsoren'}" :class="$route.name.startsWith('sponsoren') ? 'active' : ''" v-on:click="subNavi = '';moveUp();">Sponsoren</router-link>
                </span>
            </li>
            <li class="has-items" :class="subNavi === 'fluechterpreis' ? 'active' : ''">
                <span v-on:click="subNavi = (subNavi !== 'fluechterpreis') ? 'fluechterpreis' : ''">
                    Flüchterpreis
                    <span class="dropdownmenu"><svg-icon type="mdi" :path="mdiChevronDown" width="25" height="25"></svg-icon></span>
                </span>
                <ul @click="subNavi = '';TriggerNavigation()">
                    <li><router-link :to="{name: 'fluechter@view'}" :class="$route.name.startsWith('fluechter@view') ? 'active' : ''" v-on:click="moveUp">Flüchterpreis</router-link></li>
                    <li><router-link :to="{name: 'fluechter@gewinner'}" :class="$route.name.startsWith('fluechter@gewinner') ? 'active' : ''" v-on:click="moveUp">Flüchterpreis-Gewinner</router-link></li>
                </ul>
            </li>
            <li>
                <span @click="subNavi = '';TriggerNavigation()">
                    <router-link :to="{name: 'galerie@overview'}" :class="$route.name.startsWith('galerie@overview') ? 'active' : ''" v-on:click="subNavi = '';moveUp();">Bildergalerie</router-link>
                </span>
            </li>
            <li class="has-items" :class="subNavi === 'hallOfMasters' ? 'active' : ''">
                <span v-on:click="subNavi = (subNavi !== 'hallOfMasters') ? 'hallOfMasters' : ''">
                    Hall of Masters
                    <span class="dropdownmenu"><svg-icon type="mdi" :path="mdiChevronDown" width="25" height="25"></svg-icon></span>
                </span>
                <ul @click="subNavi = '';TriggerNavigation()">
                    <li><router-link :to="{name: 'hallOfFame@info'}" :class="$route.name.startsWith('hallOfFame@info') ? 'active' : ''" v-on:click="moveUp">ALS Hall of Masters</router-link></li>
                    <li><router-link :to="{name: 'hallOfFame@winner'}" :class="$route.name.startsWith('hallOfFame@winner') ? 'active' : ''" v-on:click="moveUp">ALS Hall of Masters Gewinner</router-link></li>
                </ul>
            </li>
            <li class="has-items" :class="subNavi === 'sparkassenCup' ? 'active' : ''">
                <span v-on:click="subNavi = (subNavi !== 'sparkassenCup') ? 'sparkassenCup' : ''">
                    Sparkassen Cup
                    <span class="dropdownmenu"><svg-icon type="mdi" :path="mdiChevronDown" width="25" height="25"></svg-icon></span>
                </span>
                <ul @click="subNavi = '';TriggerNavigation()">
                    <li><router-link :to="{name: 'sparkasse@winner'}" :class="$route.name.startsWith('sparkasse@winner') ? 'active' : ''" v-on:click="moveUp">Turniersieger</router-link></li>
                    <li><a href="#" target="_blank">Spielplan <svg-icon type="mdi" :path="mdiOpenInNew" width="12" height="12"></svg-icon></a></li>
                </ul>
            </li>
        </ul>

        <div id="tablet_right"></div>
    </nav>

    <div id="subnav" :class="scrollPos > headerHeight || !$route.name.startsWith('home')  ? 'fixed' : ''" v-if="subNavi === 'masters'">
        <router-link :to="{name: 'masters@entrancefeeds'}" :class="$route.name.startsWith('masters@entrancefeeds') ? 'active' : ''" v-on:click="moveUp">Eintrittspreise & Anstoßzeiten</router-link>
        <router-link :to="{name: 'masters@tournamentwinner'}" :class="$route.name.startsWith('masters@tournamentwinner') ? 'active' : ''" v-on:click="moveUp">Turniersieger</router-link>
        <router-link :to="{name: 'masters@topscorer'}" :class="$route.name.startsWith('masters@topscorer') ? 'active' : ''" v-on:click="moveUp">Torschützenkönige</router-link>
        <router-link :to="{name: 'fluechter@gewinner'}" :class="$route.name.startsWith('fluechter@gewinner') ? 'active' : ''" v-on:click="moveUp">Flüchterpreis-Gewinner</router-link>
        <router-link :to="{name: 'masters@goalkeeperprice'}" :class="$route.name.startsWith('masters@goalkeeperprice') ? 'active' : ''" v-on:click="moveUp">Torwartpreis</router-link>
        <router-link :to="{name: 'masters@prices'}" :class="$route.name.startsWith('masters@prices') ? 'active' : ''" v-on:click="moveUp">Preisgelder</router-link>
        <router-link :to="{name: 'masters@history'}" :class="$route.name.startsWith('masters@history') ? 'active' : ''" v-on:click="moveUp">Historie</router-link>
        <a href="../Turnierordnung18.pdf" target="_blank">Turnierordnung <svg-icon type="mdi" :path="mdiOpenInNew" width="12" height="12"></svg-icon></a>
    </div>

    <div id="subnav" :class="scrollPos > headerHeight || !$route.name.startsWith('home') ? 'fixed' : ''" v-if="subNavi === 'fluechterpreis'">
        <router-link :to="{name: 'fluechter@view'}" :class="$route.name.startsWith('fluechter@view') ? 'active' : ''" v-on:click="moveUp">Flüchterpreis</router-link>
        <router-link :to="{name: 'fluechter@gewinner'}" :class="$route.name.startsWith('fluechter@gewinner') ? 'active' : ''" v-on:click="moveUp">Flüchterpreis-Gewinner</router-link>
    </div>

    <div id="subnav" :class="scrollPos > headerHeight || !$route.name.startsWith('home') ? 'fixed' : ''" v-if="subNavi === 'hallOfMasters'">
        <router-link :to="{name: 'hallOfFame@info'}" :class="$route.name.startsWith('hallOfFame@info') ? 'active' : ''" v-on:click="moveUp">ALS Hall of Masters</router-link>
        <router-link :to="{name: 'hallOfFame@winner'}" :class="$route.name.startsWith('hallOfFame@winner') ? 'active' : ''" v-on:click="moveUp">ALS Hall of Masters Gewinner</router-link>
    </div>

    <div id="subnav" :class="scrollPos > headerHeight || !$route.name.startsWith('home') ? 'fixed' : ''" v-if="subNavi === 'sparkassenCup'">
        <router-link :to="{name: 'sparkasse@winner'}" :class="$route.name.startsWith('sparkasse@winner') ? 'active' : ''" v-on:click="moveUp">Turniersieger</router-link>
        <a href="#" target="_blank">Spielplan <svg-icon type="mdi" :path="mdiOpenInNew" width="12" height="12"></svg-icon></a>
    </div>

    <div id="content" :class="
    $route.name.startsWith('home') ? (scrollPos > headerHeight ? (subNavi !== '' ? 'home_big' : 'home_small') : '') :
    (subNavi !== '' ? 'big' : 'small')
  ">
        <router-view></router-view>
    </div>

    <footer>
        <section>
            <h5>Social</h5>
            <div class="item"><a href="https://www.facebook.com/ChampionMasters" target="_blank">Facebook</a></div>
        </section>

        <section>
            <h5>Rechtliches</h5>
            <div class="item"><router-link :to="{name: 'kontakt'}" v-on:click="subNavi = '';moveUp();">Kontakt</router-link></div>
            <div class="item"><router-link :to="{name: 'datenschutz'}" v-on:click="subNavi = '';moveUp();">Datenschutz</router-link></div>
            <div class="item"><router-link :to="{name: 'impressum'}" v-on:click="subNavi = '';moveUp();">Impressum</router-link></div>
        </section>
    </footer>
</template>

<script>
import "./assets/style/style.sass";
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from "jquery";
import SvgIcon from '@jamescoyle/vue-icon'
import { useHead } from "@vueuse/head"

// Icons
import { mdiOpenInNew } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { mdiChevronDown } from '@mdi/js';

export default {
    name: "App",
    components: {
        SvgIcon,
    },
    created()
    {
        useHead({
            title: "Startseite - " + this.tournamentName,
            meta: [
                {
                    name: `description`,
                    content: "Das ALS ChampionMasters ist ein Hallenturnier, welches jährlich Ende Dezember über 3 Tage stattfindet."
                },
            ],
        });
    },
    data() {
        return {
            scrollPos: 0,
            headerHeight: $("header").height(),
            alreadyFixed: false,
            subNavi: "",
            showMainNavi: false,
            finishToggle: true,

            // Icons
            mdiOpenInNew: mdiOpenInNew,
            mdiMenu: mdiMenu,
            mdiChevronDown: mdiChevronDown
        };
    },
    mounted() {
        $(window).scroll(() => {
            let scrollPos = $(document).scrollTop();
            this.ScrollingEvent(scrollPos);
        });
    },
    methods: {
        TriggerNavigation() {
            if(this.finishToggle)
            {
                this.finishToggle = false;

                if(this.showMainNavi)
                {
                }
                else
                {
                }

                this.showMainNavi = !this.showMainNavi;
                this.finishToggle = true;
            }
        },

        ScrollingEvent(pos) {
            this.scrollPos = pos;
            let headerHeight = $("header").height();

            if(!$("#topbar").is(":hidden"))
            {
                headerHeight += $("#topbar").height()
            }

            this.headerHeight = headerHeight;

            if (pos >= 0 && pos < headerHeight) {
                let factor = 1 - pos / headerHeight;
                $("#playtimes").css("opacity", factor);
                $("#cm_logo").css("opacity", factor);
                $("#cm_logo").css("min-width", 320 * factor);
                $("#cm_logo").css("min-height", 320 * factor);
                $("#cm_logo").css("width", 320 * factor);
                $("#cm_logo").css("height", 320 * factor);
                $("#cm_small_logo").css("opacity", 1 - factor);

                // Top vom Logo
                $("#cm_logo").css("margin-top", pos);
            }

            if(!this.$route.name.startsWith('home'))
            {
                $("#cm_small_logo").css("opacity", 1);
            }
        },
        moveUp()
        {
            setTimeout(() => {
                this.ScrollingEvent(0);
                window.scrollTo(0,0);
            }, 125);
        },
    },
};
</script>
